#App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #333333;
}


#App .title {
  margin: 3px 0px 10px 0px;
  color: #cccccc;
}

#App .section_title {
  margin: 10px;
  font-size: 2.5em;
  padding-bottom: 10px;
  border-bottom: 2px solid #141414;
  color: white;
}


#content_column {
  width: 1400px;
}

#page_item_form_container {
  width: 100%;
  height: 900px;
  display: flex;
  justify-content: space-around;
  border-radius: 5px;
  padding: 10px 0px;
}

#page_item_form_container .form_container {
  width: 48%;
  height: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}


#page_item_form_container .form_container #output_object_container {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  overflow-y: auto;
  background-color: #282828;
  border-radius: 5px;
  border: 1px solid #2b2b2b;
}

.row_space_between {
  width: 100%;
  display: flex;
  justify-content: space-between;
}


.section_container {
  width: calc(100% - 40px - 20px);
  background-color: #282828;
  border-left: 6px solid #141414;
  border-right: 6px solid #141414;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0px;
  padding: 20px;
  border-radius: 5px;
  color: white;
}

.action_button {
  width: 150px;
  height: 50px;
  margin: 40px 15px 15px 15px;
  border-radius: 5px;
  color: white;
  font-size: 1.3em;
}

.action_button:hover {
  cursor: pointer;
}

#npm_button {
  background-color: #ba0e3f;
  border-color: #ba0e3f;
}

#npm_button:hover {
  background-color: #ec1150;
  border-color: #ec1150;
}

#github_button {
  background-color: #117aa0;
  border-color: #117aa0;
}

#github_button:hover {
  background-color: #19b1ea;
  border-color: #19b1ea;
}

#get_started_button {
  background-color: #12a855;
  border-color: #12a855;
}

#get_started_button:hover {
  background-color: #17e573;
  border-color: #17e573;
}

#demo_title {
  font-size: 3.1em;
  margin: 10px;
  color: white;
}

#conjure_logo {
  width: 250px;
}

.demo_description {
  margin: 5px;
  font-size: 1.3em;
}

.boundary_line_container {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
}

.boundary_line_subsection {
  width: 33%;
  height: 100%;
}

a {
  color: #5cc3ea;
}
