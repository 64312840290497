#DropDown {
  width: 100%;
  height: 100%;
  padding: 5px 0px;
  position: relative;
  cursor: pointer;
}

#DropDown .option_container_retracted {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #dddddd;
  border-radius: 3px;
}

#DropDown .option_container_retracted:hover {
  background-color: #cccccc;
}

#DropDown .option_text {
  margin: 0px 10px;
}

#DropDown .dropdown_status_text {
  margin: 0px 10px;
}


#DropDown #expanded_container {
  position: absolute;
  width: 100%;
  background-color: red;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 4px;
}


#DropDown .option_container {
  width: 100%;
  background-color: #2b2b2b;
  height: 40px;
  display: flex;
  align-items: center;
  color: #dddddd;
}

#DropDown .option_container:hover {
  background-color: black;
}


#DropDown .option_container_selected {
  width: 100%;
  background-color: #19b1ea;
  height: 40px;
  display: flex;
  align-items: center;
}
